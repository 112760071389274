import React from 'react';
import naruto from './Gifs/naruto.gif';
import Aws from './aws';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Navigation from './navigation'; 

interface IProps {
}

interface IState {
  urls: string[];
  directories: string[];
  aboutMeDisplay: any;
}

export default class App extends React.Component<IProps, IState>{

  constructor(props:any){
    super(props)
    this.state={
      urls: [],
      directories: [],
      aboutMeDisplay:'none'
    }
  }

  componentDidMount = async (): Promise<void> => {
      const aws = new Aws();
      const urlsList = await aws.getCloudfrontUrls();
      const directoriesList = await aws.getDirectories();
      
      this.setState((state,props) =>{
        const newState = {
          urls: urlsList,
          directories: directoriesList
        };
        return newState;
      });

      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
      head?.appendChild(script);
  }

  headerOnClick = async (event:any) => {
    const selectedMoment = event.target.innerText;
    if(selectedMoment === 'ABOUT ME'){
      this.setState((state,props) =>{
        const newState = {
          aboutMeDisplay: 'block'
        };
        return newState;
      })
    }else{
      this.setState((state,props) =>{
        const newState = {
          aboutMeDisplay: 'none'
        };
        return newState;
      })
    }
    try{
      const aws = new Aws();
      const urlsList = await aws.getCloudfrontUrls(selectedMoment);
      this.setState((state,props) =>{
        const newState = {
          urls: urlsList
        };
        return newState;
      })
    }catch(err){
      console.log(err);
    }
    return true;
  };


  render(): React.ReactNode {
    const imagesIsLoaded = this.state.urls.length > 0;
    if(imagesIsLoaded){
      return (
        <Box>
          <Navigation dir={this.state.directories} headerOnClick={this.headerOnClick} ></Navigation>
          <ImageList
            sx={{}}
            variant="masonry"
            cols={4}
            gap={20}
          >
            {this.state.urls.map((photo,index) => (
              <ImageListItem key={index} cols={4} rows={4}>
                <img
                  src={photo}
                  alt={"Testing"}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
          <div className="calendly-inline-widget" data-url="https://calendly.com/pixelpix?hide_gdpr_banner=1&background_color=1976d2&text_color=ffffff&primary_color=000000" style={{minWidth:'300px',height:'700px'}} />
          <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script> 
        </Box>
      )
    }else{
      return (
        <Box>
          <img src={naruto} alt="loading" ></img>
        </Box>
      )
    }
  }
}