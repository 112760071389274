
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import WhiteLogo from './Logos/WhiteLogo.png';
import InstagramLogo from './Logos/InstagramLogo.png';
import { style } from '@mui/system';

interface IProps {
  dir: string[],
 // headerOnClick: func
 headerOnClick: (event:any) => Promise<boolean>;
 //oSomething: (data: object) => boolean; // function as property declaration
}

interface IState {
  directories:string[],
}

export default class Navigation extends React.Component<IProps, IState>{

  constructor(props:any){
    super(props);
    this.state={
      directories:props.dir
    }
  }

  render(): React.ReactNode {
    return (
      
   
          <Toolbar disableGutters sx={{overflowX:'scroll'}}>
            <a href="/">
              <img width="125" height="125" src={WhiteLogo} alt="Main Logo"></img>
            </a>
            <a href="https://www.instagram.com/pixelpixphotos" rel="noreferrer" target="_blank" >
              < img width="50" height="50" src={InstagramLogo} alt="Instagram Logo" ></img>
            </a>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
              {this.state.directories.map((page) => (
                <Button
                  onClick={ (e) => {this.props.headerOnClick(e)}}
                  key={page} 
                  sx={{ my: 2, color: 'white', display: 'block',textAlign:'center'}}
                  style={{fontSize:'20px',padding:'20px'}}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Toolbar>
       
      
    )
  }
}
