import axios from 'axios';
import { S3Client, ListObjectsV2Command  } from '@aws-sdk/client-s3';

export default class Aws{
    accessKeyId: string;
    SecretAccessKey:string;
    region:string;

    constructor(){
        this.accessKeyId='AKIA5C5FY7J5HMPYAKX7';
        this.SecretAccessKey='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';
        this.region='us-east-2'
    }

    getConfig = () => {
        const config = {
            credentials: {
                accessKeyId: this.accessKeyId,
                secretAccessKey: this.SecretAccessKey
            },
            region: this.region
        };
        return config;
    }

    getCloudfrontUrls = async (moment='Weddings'): Promise<string[]> =>{
        const bucketName = 'pixel-pix-photos'
        const firstLetterOfMoment = moment.slice(0,1)
        const restOfMomentInLowercase = moment.split(firstLetterOfMoment)[1].toLowerCase();
        const actualMoment = firstLetterOfMoment + restOfMomentInLowercase;
        const getUri = `geturls?bucket=${bucketName}&prefix=${actualMoment}`;
        const getEndpoint = `https://6ojcgjvy7b.execute-api.us-east-2.amazonaws.com/prod/${getUri}`;
        const result = await axios.get(getEndpoint);
        return result.data.urls;
    }

    
    getDirectories = async ():  Promise<string[]> => {
        const awsConfig = this.getConfig();
        const client = new S3Client(awsConfig);
        const bucketName = 'pixel-pix-photos'
        const listInput = {
            Bucket: bucketName,
            Delimiter: '/'
        };
        let directories:any = [];
        try{
            const listCommand = new ListObjectsV2Command(listInput);
            const listResponse = await client.send(listCommand);
            const moments = listResponse.CommonPrefixes;
            directories = moments?.map(moment => {
                return moment.Prefix?.replace('/','');
            });
        }catch(err){
            console.log(`AWS failure: ${err} `)
        }
        return directories
        // await this.setStateDirectories(await directories);
    };
}